import { getTitleCaseTranslation } from '@/services/TranslationService';

export enum PropulsionType {
    Electric = 1,
    Gas = 2,
}

export function mapPropulsionTypeToName(type: PropulsionType | undefined): string {
    switch (type) {
    case PropulsionType.Electric:
        return getTitleCaseTranslation('core.common.electric');
    case PropulsionType.Gas:
        return getTitleCaseTranslation('core.common.gas');
    default:
        return '';
    }
}

export type PropulsionTypeSelectItem = {
    value: PropulsionType | undefined;
    text: String;
};

export function allPropulsionTypeOptions(): Array<PropulsionTypeSelectItem> {
    return [{
        value: PropulsionType.Electric,
        text: getTitleCaseTranslation('core.common.electric'),
    },
    {
        value: PropulsionType.Gas,
        text: getTitleCaseTranslation('core.common.gas'),
    }];
}

export default class Equipment {
    constructor(init?: Partial<Equipment>) {
        Object.assign(this, init);
        this.isOwned = init?.isOwned || false;
    }

    public id: number = 0;

    public name!: string;

    public equipmentNumber!: string;

    public typeId!: number;

    public typeDescription!: string;

    public make?: string;

    public model?: string;

    public serialNumber?: string;

    public propulsionType?: PropulsionType;

    public propulsionName?: string;

    public locationId!: number;

    public locationName?: string;

    public inService: boolean = true;

    public isOwned!: boolean;
}
