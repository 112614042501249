import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, toDisplayString as _toDisplayString, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createTextVNode as _createTextVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_text_input = _resolveComponent("text-input")!
  const _component_select_input = _resolveComponent("select-input")!
  const _component_b_row = _resolveComponent("b-row")!
  const _component_checkbox_input = _resolveComponent("checkbox-input")!
  const _component_b_form = _resolveComponent("b-form")!
  const _component_b_spinner = _resolveComponent("b-spinner")!
  const _component_b_button = _resolveComponent("b-button")!
  const _component_b_modal = _resolveComponent("b-modal")!
  const _component_faicon = _resolveComponent("faicon")!
  const _component_b_dropdown_item = _resolveComponent("b-dropdown-item")!
  const _component_b_dropdown = _resolveComponent("b-dropdown")!
  const _component_b_advanced_table = _resolveComponent("b-advanced-table")!
  const _component_screen = _resolveComponent("screen")!

  return (_openBlock(), _createBlock(_component_screen, { full: "" }, {
    default: _withCtx(() => [
      _createVNode(_component_b_modal, {
        modelValue: _ctx.state.showForm,
        "onUpdate:modelValue": _cache[10] || (_cache[10] = ($event: any) => ((_ctx.state.showForm) = $event)),
        centered: "",
        size: "xl",
        title: _ctx.state.modalTitle
      }, {
        "btn-ok": _withCtx(() => [
          _createVNode(_component_b_button, {
            variant: "primary",
            disabled: _ctx.state.saving,
            onClick: _ctx.save
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.save')) + " ", 1),
              (_ctx.state.saving)
                ? (_openBlock(), _createBlock(_component_b_spinner, {
                    key: 0,
                    small: ""
                  }))
                : _createCommentVNode("", true)
            ]),
            _: 1
          }, 8, ["disabled", "onClick"])
        ]),
        default: _withCtx(() => [
          _createVNode(_component_b_form, null, {
            default: _withCtx(() => [
              _createVNode(_component_b_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_text_input, {
                    modelValue: _ctx.state.equipmentEdit.name,
                    "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.state.equipmentEdit.name) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.name'),
                    cols: "3",
                    error: _ctx.validationResult.model.name
                  }, null, 8, ["modelValue", "label", "error"]),
                  _createVNode(_component_text_input, {
                    modelValue: _ctx.state.equipmentEdit.equipmentNumber,
                    "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.state.equipmentEdit.equipmentNumber) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.equipmentNumber'),
                    cols: "3",
                    error: _ctx.validationResult.model.equipmentNumber
                  }, null, 8, ["modelValue", "label", "error"]),
                  _createVNode(_component_select_input, {
                    model: _ctx.state.equipmentEdit.typeId,
                    label: _ctx.getTitleCaseTranslation('core.domain.type'),
                    options: _ctx.store.equipmentTypes,
                    loading: _ctx.state.loading,
                    text: "description",
                    value: "id",
                    cols: "3",
                    error: _ctx.validationResult.model.typeId,
                    onChange: _cache[2] || (_cache[2] = (value) => _ctx.setEquipmentType(value))
                  }, null, 8, ["model", "label", "options", "loading", "error"]),
                  _createVNode(_component_text_input, {
                    modelValue: _ctx.state.equipmentEdit.make,
                    "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.state.equipmentEdit.make) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.make'),
                    cols: "3",
                    error: _ctx.validationResult.model.make
                  }, null, 8, ["modelValue", "label", "error"])
                ]),
                _: 1
              }),
              _createVNode(_component_b_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_text_input, {
                    modelValue: _ctx.state.equipmentEdit.model,
                    "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.state.equipmentEdit.model) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.model'),
                    cols: "3",
                    error: _ctx.validationResult.model.model
                  }, null, 8, ["modelValue", "label", "error"]),
                  _createVNode(_component_text_input, {
                    modelValue: _ctx.state.equipmentEdit.serialNumber,
                    "onUpdate:modelValue": _cache[5] || (_cache[5] = ($event: any) => ((_ctx.state.equipmentEdit.serialNumber) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.serialNumber'),
                    cols: "3",
                    error: _ctx.validationResult.model.serialNumber
                  }, null, 8, ["modelValue", "label", "error"]),
                  _createVNode(_component_select_input, {
                    model: _ctx.state.equipmentEdit?.propulsionType,
                    label: _ctx.getTitleCaseTranslation('core.domain.propulsion'),
                    options: _ctx.propulsionTypes,
                    loading: _ctx.state.loading,
                    text: "text",
                    value: "value",
                    cols: "3",
                    onChange: _cache[6] || (_cache[6] = (value) => _ctx.setPropulsionType(value))
                  }, null, 8, ["model", "label", "options", "loading"]),
                  _createVNode(_component_select_input, {
                    model: _ctx.state.equipmentEdit?.locationId,
                    label: _ctx.getTitleCaseTranslation('core.domain.location'),
                    options: _ctx.availableLocations,
                    loading: _ctx.state.loading,
                    error: _ctx.validationResult.model.locationId,
                    text: "name",
                    value: "id",
                    cols: "3",
                    onChange: _cache[7] || (_cache[7] = (value) => _ctx.setLocation(value))
                  }, null, 8, ["model", "label", "options", "loading", "error"])
                ]),
                _: 1
              }),
              _createVNode(_component_b_row, null, {
                default: _withCtx(() => [
                  _createVNode(_component_checkbox_input, {
                    modelValue: _ctx.state.equipmentEdit.isOwned,
                    "onUpdate:modelValue": _cache[8] || (_cache[8] = ($event: any) => ((_ctx.state.equipmentEdit.isOwned) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.owned'),
                    cols: "3",
                    "inline-right": "",
                    class: "form-col"
                  }, null, 8, ["modelValue", "label"]),
                  _createVNode(_component_checkbox_input, {
                    modelValue: _ctx.state.equipmentEdit.inService,
                    "onUpdate:modelValue": _cache[9] || (_cache[9] = ($event: any) => ((_ctx.state.equipmentEdit.inService) = $event)),
                    label: _ctx.getTitleCaseTranslation('core.domain.inService'),
                    cols: "3",
                    "inline-right": "",
                    class: "form-col"
                  }, null, 8, ["modelValue", "label"])
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 8, ["modelValue", "title"]),
      _createVNode(_component_b_advanced_table, {
        "table-array": [_ctx.table],
        "sticky-header": "calc(100vh - 105px)",
        loading: _ctx.state.loading,
        "dynamic-columns": ""
      }, {
        toprow: _withCtx(() => [
          _createVNode(_component_b_button, {
            size: "sm",
            variant: "tertiary",
            disabled: !_ctx.Permissions.ADMINISTRATION.canEditEquipment(),
            "disabled-message": _ctx.getTranslation('core.common.youDoNotHavePermissionToAddEquipment'),
            onClick: _ctx.openAdd
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.addEquipment')), 1)
            ]),
            _: 1
          }, 8, ["disabled", "disabled-message", "onClick"])
        ]),
        action: _withCtx((row) => [
          _createVNode(_component_b_dropdown, {
            "no-caret": "",
            "close-on-click": ""
          }, {
            "button-content": _withCtx(() => [
              _createVNode(_component_faicon, { icon: "ellipsis-h" })
            ]),
            default: _withCtx(() => [
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                disabled: !_ctx.Permissions.ADMINISTRATION.canEditEquipment(),
                onClick: () => _ctx.openEdit(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.edit')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                disabled: !_ctx.Permissions.ADMINISTRATION.canEditEquipment(),
                onClick: () => _ctx.deleteEquipment(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.delete')), 1)
                ]),
                _: 2
              }, 1032, ["disabled", "onClick"]),
              _createVNode(_component_b_dropdown_item, {
                size: "sm",
                onClick: () => _ctx.openEquipmentHistory(row.item)
              }, {
                default: _withCtx(() => [
                  _createTextVNode(_toDisplayString(_ctx.getTitleCaseTranslation('core.button.showHistory')), 1)
                ]),
                _: 2
              }, 1032, ["onClick"])
            ]),
            _: 2
          }, 1024)
        ]),
        _: 1
      }, 8, ["table-array", "loading"])
    ]),
    _: 1
  }))
}