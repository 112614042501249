
import {
    defineComponent, reactive, onBeforeMount, computed,
} from 'vue';
import EquipmentType from '@/domain/EquipmentType';
import EquipmentService from '@/services/EquipmentService';
import Location from '@/domain/Location';
import { TableDefinition } from '@/types';
import Permissions from '@/services/permissions/Permissions';
import useValidator from '@/validation/useValidator';
import BSpinner from '@/components/bootstrap-library/BSpinner.vue';
import MasterDataRouteTypes from '../../routes/types';
import EntityType from '@/domain/enums/EntityTypes';
import router from '@/router';
import { getTitleCaseTranslation, getTranslation } from '@/services/TranslationService';
import masterDataStore from '../../store/MasterDataStore';
import CheckboxInput from '@/components/inputs/CheckboxInput.vue';
import useDialogBox from '@/components/bootstrap-library/composables/useDialogBox';
import { StaticLocationType } from '@/domain/LocationType';
import Equipment, { allPropulsionTypeOptions, mapPropulsionTypeToName, PropulsionTypeSelectItem } from '@/domain/Equipment';

type State = {
    equipment: Array<Equipment>;
    equipmentEdit: Equipment;
    loading: boolean;
    modalTitle: string;
    saving: boolean;
    showForm: boolean;
};

export default defineComponent({
    name: 'equipment-list',
    components: {
        BSpinner,
        CheckboxInput,
    },

    setup() {
        const equipmentService = new EquipmentService();
        const store = masterDataStore.getInstance().configStore;
        const { locationStore } = masterDataStore.getInstance();
        const { confirm } = useDialogBox();
        const { validateForm, validationResult, clearResults } = useValidator<Equipment>('equipment');
        const propulsionTypes = allPropulsionTypeOptions();

        const state = reactive<State>({
            equipment: [],
            loading: true,
            equipmentEdit: new Equipment(),
            showForm: false,
            modalTitle: '',
            saving: false,
        });

        onBeforeMount(async () => {
            state.loading = true;

            const equipmentResponse = await equipmentService.getAllEquipment();
            if (equipmentResponse.success) {
                state.equipment = equipmentResponse.equipment;
            }

            state.loading = false;
        });

        const availableLocations = computed(() => (
            locationStore.locations.filter((loc) => (loc.type.staticTypeIdentifier === StaticLocationType.ServiceCenter
            || loc.type.staticTypeIdentifier === StaticLocationType.ManagedWarehouse))
        ));

        function getTypeDescriptionById(equipmentTypeId: number) {
            const equipmentType = store.equipmentTypes.find((x) => x.id === equipmentTypeId);
            return equipmentType?.description || '';
        }

        function getLocationNameById(locationId: number) {
            const location = availableLocations.value.find((x) => x.id === locationId);
            return location?.name || '';
        }

        const equipmentTableData = computed(
            (): Array<Equipment> => state.equipment.map(
                (data: Equipment) => ({
                    id: data.id,
                    name: data.name,
                    equipmentNumber: data.equipmentNumber,
                    typeId: data.typeId,
                    typeDescription: getTypeDescriptionById(data.typeId),
                    make: data.make,
                    model: data.model,
                    serialNumber: data.serialNumber,
                    propulsionType: data.propulsionType,
                    propulsionName: mapPropulsionTypeToName(data.propulsionType),
                    locationId: data.locationId,
                    locationName: data.locationId ? getLocationNameById(data.locationId) : '',
                    inService: data.inService,
                    isOwned: data.isOwned,
                } as Equipment),
            ),
        );

        const table = computed(
            (): TableDefinition<Equipment> => ({
                items: equipmentTableData.value,
                key: 'equipmentList',
                name: getTitleCaseTranslation('core.domain.equipment'),
                columnDefinition: [
                    {
                        key: 'name',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.name'),
                    },
                    {
                        key: 'equipmentNumber',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.equipmentNumber'),
                    },
                    {
                        key: 'typeDescription',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.type'),
                    },
                    {
                        key: 'make',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.make'),
                    },
                    {
                        key: 'model',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.model'),
                    },
                    {
                        key: 'serialNumber',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.serialNumber'),
                    },
                    {
                        key: 'propulsionName',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.propulsion'),
                    },
                    {
                        key: 'locationName',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.location'),
                    },
                    {
                        key: 'isOwned',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.owned'),
                    },
                    {
                        key: 'inService',
                        searchable: true,
                        label: getTitleCaseTranslation('core.domain.inService'),
                    },
                ],
            }),
        );

        function openAdd() {
            clearResults();
            state.equipmentEdit = new Equipment();
            state.modalTitle = getTitleCaseTranslation('core.common.newEquipment');
            state.showForm = true;
        }

        function openEdit(equipment: Equipment) {
            clearResults();
            state.equipmentEdit = new Equipment(equipment);
            state.modalTitle = getTitleCaseTranslation('core.common.editEquipment');
            state.showForm = true;
        }

        async function openEquipmentHistory(equipment: Equipment) {
            router.push({
                name: MasterDataRouteTypes.HISTORY.LIST,
                params: { entityType: EntityType.EQUIPMENT, entityId: equipment.id },
            });
        }

        async function deleteEquipment(equipment: Equipment) {
            const response = await confirm({
                title: getTranslation('core.common.areYouSure'),
                message: getTitleCaseTranslation('core.common.deleteEquipment'),
            });
            if (!response) {
                return;
            }

            const deleted = await equipmentService.deleteEquipment(equipment);
            if (deleted) {
                state.equipment = state.equipment.filter((r) => r.id !== equipment.id);
            }
        }

        async function save() {
            validateForm(state.equipmentEdit);
            if (validationResult.isValid) {
                state.saving = true;

                if (state.equipmentEdit.id > 0) {
                    const response = await equipmentService.updateEquipment(state.equipmentEdit);
                    if (response) {
                        state.equipment = state.equipment.map((data) => {
                            if (data.id === state.equipmentEdit.id) {
                                return state.equipmentEdit;
                            }
                            return data;
                        });
                        state.showForm = false;
                    }
                } else {
                    const response = await equipmentService.addNewEquipment(state.equipmentEdit);
                    if (response) {
                        state.equipment.unshift(response);
                        state.showForm = false;
                    }
                }

                state.saving = false;
            }
        }

        async function setEquipmentType(equipmentType: EquipmentType) {
            state.equipmentEdit.typeId = equipmentType.id;
        }

        async function setPropulsionType(value?: PropulsionTypeSelectItem) {
            state.equipmentEdit.propulsionType = value?.value;
        }

        async function setLocation(location: Location) {
            state.equipmentEdit.locationId = location.id;
            state.equipmentEdit.locationName = location.name;
        }

        return {
            deleteEquipment,
            getTitleCaseTranslation,
            getTranslation,
            state,
            openAdd,
            openEdit,
            openEquipmentHistory,
            Permissions,
            propulsionTypes,
            save,
            availableLocations,
            setEquipmentType,
            setLocation,
            setPropulsionType,
            store,
            table,
            validationResult,
        };
    },
});
